import Vue from 'vue'
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        show: false,
        productList: [],
        budget: 0,
        valuador_hide: true,
    },
    mutations: {
        setShow(state, payload) {
            state.show = payload
        },
        setProductList(state, payload) {
            state.productList = payload
        },
        setBudget(state, payload) {
            state.budget = payload
        },
        setValuadorHide(state, payload) {
            state.valuador_hide = payload
        },
    },
})