
<div :class="popupClass">
    <div v-show="popupClass === 'popup-chatbot-open'" class="avatar-container" @click="openMenu;">
        <img :src="rooms[0].avatar" alt="chatbot-avatar"/>
    </div>
    <div class="chat-container" v-show="popupClass === 'popup-chatbot-open'">
        <vue-advanced-chat :accepted-files="acceptedFiles"
                           :current-user-id="currentUserId"
                           :height="height"
                           :menu-actions="JSON.stringify(menuActions)"
                           :messages="JSON.stringify(messages)"
                           :messages-loaded="messagesLoaded"
                           :rooms="JSON.stringify(rooms)"
                           :rooms-loaded="true"
                           :show-audio="JSON.stringify(false)"
                           :show-files="JSON.stringify(showFiles)"
                           :show-emojis="JSON.stringify(false)"
                           :show-footer="showFooter"
                           :show-reaction-emojis="false"
                           :single-room="true"
                           :styles="JSON.stringify(styles)"
                           style="overflow: auto; text-align: left"
                           :text-messages="JSON.stringify(textMessages)"
                           ref="vac"
                           @send-message="sendMessage($event.detail[0])"
                           @fetch-messages="fetchMessages($event.detail[0])"
                           @menu-action-handler="menuActionHandler($event.detail[0])"
                           @open-file="openFile($event.detail[0])">
            <div slot="emoji-picker-icon"></div>
            <div slot="room-header" class="chat-header">
                <div class="header-content px-3">
                    <div class="burger-button" @click="$emit('chatbot-valuador-close')">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <i class="fa-regular fa-circle-xmark fa-2xl" style="color: #ffffff;"
                       @click="menuActionHandler({action: {name: 'close'}})"></i>
                </div>
                <div class="bottom-caret"></div>
            </div>
        </vue-advanced-chat>
    </div>
    <div v-show="popupClass === 'popup-chatbot-close'" class="w-100 h-100" @click="openMenu">
        <div v-show="showNotification" class="chatbot-notification">
            <p class="chatbot-notification-container">{{ notificationCount }}</p>
        </div>
        <div class="avatar-container">
            <img :src="rooms[0].avatar" alt="avatar"/>
        </div>
    </div>
</div>
