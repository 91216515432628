<template>
    <div :class="popupClass">
        <div v-show="popupClass === 'popup-chatbot-open'" class="avatar-container" @click="openMenu;">
            <img :src="rooms[0].avatar" alt="chatbot-avatar"/>
        </div>
        <div class="chat-container" v-show="popupClass === 'popup-chatbot-open'">
            <vue-advanced-chat :accepted-files="acceptedFiles"
                               :current-user-id="currentUserId"
                               :height="height"
                               :menu-actions="JSON.stringify(menuActions)"
                               :messages="JSON.stringify(messages)"
                               :messages-loaded="messagesLoaded"
                               :rooms="JSON.stringify(rooms)"
                               :rooms-loaded="true"
                               :show-audio="JSON.stringify(false)"
                               :show-files="JSON.stringify(showFiles)"
                               :show-emojis="JSON.stringify(false)"
                               :show-footer="showFooter"
                               :show-reaction-emojis="false"
                               :single-room="true"
                               :styles="JSON.stringify(styles)"
                               style="overflow: auto; text-align: left"
                               :text-messages="JSON.stringify(textMessages)"
                               ref="vac"
                               @send-message="sendMessage($event.detail[0])"
                               @fetch-messages="fetchMessages($event.detail[0])"
                               @menu-action-handler="menuActionHandler($event.detail[0])"
                               @open-file="openFile($event.detail[0])">
                <div slot="emoji-picker-icon"></div>
                <div slot="room-header" class="chat-header">
                    <div class="header-content px-3">
                        <div class="burger-button" @click="$emit('chatbot-valuador-close')">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <i class="fa-regular fa-circle-xmark fa-2xl" style="color: #ffffff;"
                           @click="menuActionHandler({action: {name: 'close'}})"></i>
                    </div>
                    <div class="bottom-caret"></div>
                </div>
            </vue-advanced-chat>
        </div>
        <div v-show="popupClass === 'popup-chatbot-close'" class="w-100 h-100" @click="openMenu">
            <div v-show="showNotification" class="chatbot-notification">
                <p class="chatbot-notification-container">{{ notificationCount }}</p>
            </div>
            <div class="avatar-container">
                <img :src="rooms[0].avatar" alt="avatar"/>
            </div>
        </div>
    </div>
</template>

<script>
import {register} from 'vue-advanced-chat';
import imageCompression from 'browser-image-compression';

register();

export default {
    props: {avatar: String, budget: Number, openChatbot: Boolean, productList: Array,},
    inject: ['weight_units'],
    data() {
        return {
            popupClass: "popup-chatbot-open",
            height: "450px",
            acceptedFiles: "image/png, image/jpeg",
            session_id: this.uuidv4(),
            correlation_id: this.uuidv4(),
            conversation_id: 0,
            contact_email: '',
            contact_id: 0,
            firstContactMsg: true,
            socket: null,
            URLSocket: '',
            emailRegEx: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            currentUserId: '1234',
            rooms: [
                {
                    roomId: '1',
                    roomName: 'Chatbot',
                    avatar: this.avatar,
                    users: [
                        {_id: '1234', username: 'John Doe'},
                        {_id: '4321', username: 'John Snow'}
                    ]
                }
            ],
            styles: {
                container: {
                    borderShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                    borderRadius: '1rem 1rem 0 0',
                    background: 'var(--prestaprenda-orange)',
                },
                content: {
                    background: 'white'
                },
                header: {
                    height: '10px',
                    color: 'white'
                },
                message: {
                    background: "var(--background-gray)",
                },
                icons: {
                    paperclip: 'var(--prestaprenda-blue)',
                    send: 'var(--prestaprenda-blue)',
                    microphone: 'var(--prestaprenda-blue)',
                    document: '#fff'
                }
            },
            textMessages: {
                NEW_MESSAGES: 'Nuevos mensajes',
                MESSAGES_EMPTY: 'No hay mensajes',
                CONVERSATION_STARTED: 'Conversación iniciada:',
                TYPE_MESSAGE: 'Escribe un mensaje',
            },
            messages: [],
            messagesLoaded: false,
            menuActions: [
                {name: 'close', title: 'Cerrar chatbot'},
            ],
            msgDisableActions: true,
            msgDisableReactions: true,
            url: '',
            tipos: {
                'Joya de Oro': 'oro',
                'Moneda de Oro': 'oro',
                'Lámina de Oro': 'oro',
                'Moneda de Plata': 'plata'
            },
            getMessages: false,
            showNotification: false,
            notificationCount: 0,
            initialMessage: "",
            retryMessage: "",
            dateOptions: {
                weekday: 'short',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            },
            showFooter: true,
            showFiles: false,
            register_given_name: false,
            register_last_name: false,
            disconnect_counter: 0,
        }
    },
    created() {
        let ssWSURL = sessionStorage.getItem('ws_url')
        let ssConversationId = sessionStorage.getItem('conversation_id')
        let ssContactEmail = sessionStorage.getItem('contact_email')

        if (window.location.pathname === '/valuador/empeno/cuanto-necesitas') {
            ssWSURL = null
            ssConversationId = null
            sessionStorage.setItem('valuador_active', 'true')
        } else {
            sessionStorage.setItem('valuador_active', 'false')
        }

        if (ssWSURL && ssConversationId && ssContactEmail && this.productList.length === 0) {
            this.URLSocket = ssWSURL
            this.conversation_id = Number(ssConversationId)
            this.contact_email = ssContactEmail
            this.getMessages = true
        } else {
            this.generate_ws_url()
        }

        this.websocketConfig()
    },
    mounted() {
        this.addCustomStyle()
    },
    methods: {
        addCustomStyle() {
            const style = document.createElement('style')
            style.textContent = `.vac-icon-textarea {
                align-items: flex-end !important;
                padding-bottom: 12px;
            }

            #roomTextarea {
                max-height: 40px;
            }

            @media (max-width: 768px) {
                #messages-list {
                    margin-top: 65px !important;
                }
            }

            @media only screen and (min-width: 768px) {
                .vac-message-wrapper {
                    .vac-message-container {
                        padding: 2px 3px 1px;
                    }

                    .vac-message-container-offset {
                        margin-top: 10px;
                    }

                    .vac-message-box {
                        flex: 0 0 70%;
                        max-width: 70%;
                    }

                    .vac-offset-current {
                        margin-left: 30%;
                    }
                }
            }
            `
            this.$refs.vac.shadowRoot.appendChild(style)
        },
        websocketConfig() {
            this.socket = new WebSocket(this.URLSocket)

            this.socket.onopen = (event) => {
                let ssSessionId = sessionStorage.getItem('session_id')
                let ssTimestamp = sessionStorage.getItem('timestamp')
                if (ssTimestamp) {
                    const historyMessage = {
                        "type": "history",
                        "session_id": ssSessionId,
                        "timestamp": Number(ssTimestamp)
                    }
                    this.socket.send(JSON.stringify(historyMessage))
                    this.showFooter = true
                }
            }

            this.socket.onmessage = async (event) => {
                const message = JSON.parse(event.data)

                if (message.text.toLowerCase().includes("cómo te llamas")) {
                    this.register_given_name = true
                }

                if (message.text.toLowerCase().includes("apellido")) {
                    this.register_last_name = true
                }

                if (message.expired) {
                    sessionStorage.removeItem('ws_url')
                    sessionStorage.removeItem('conversation_id')
                    sessionStorage.removeItem('timestamp')
                    sessionStorage.removeItem('session_id')
                    sessionStorage.removeItem('contact_email')
                    this.showFooter = false
                }

                if (message.messages) {
                    sessionStorage.removeItem('timestamp')
                    for (const msg of message.messages) {
                        await this.pushBotUserMsg(msg)
                    }
                } else {
                    await this.pushBotUserMsg(message)
                    if (this.popupClass === 'popup-chatbot-close') {
                        this.showNotification = true
                        this.notificationCount++
                    }
                }
            }

            this.socket.onclose = (event) => {
                let ssTimestamp = sessionStorage.getItem('timestamp')
                if (!ssTimestamp) sessionStorage.setItem('timestamp', Math.floor(Date.now() / 1000).toString())
                this.showFooter = false
                setTimeout(() => {
                    this.disconnect_counter++
                    if (this.disconnect_counter < 5) {
                        this.websocketConfig()
                    } else {
                        this.websocketDisconnectedError()
                    }
                }, 5000)
            }
        },
        async get_chatbot_initial_messages() {
            try {
                const response = await this.$axios.get('/valuador/get_chatbot_initial_messages')
                let email_messages = response.data.email_messages.content
                this.initialMessage = email_messages.find(message => message.slug === 'initial-message').values.message.value
                this.retryMessage = email_messages.find(message => message.slug === 'retry-message').values.message.value
            } catch (error) {
                console.error(error)
            }
        },
        generate_ws_url() {
            const dudas = (this.productList.length === 0)
            const client_code = dudas ? "TSEC_Presta_Prenda_WEB_Atn_Clientes_Dudas" : "TSEC_Presta_Prenda_WEB_Atn_Clientes_Valuador"
            const schema = "wss"
            const hostname = this.$config.IS_DEV ? "grutest.inceptia.ai" : "gruprima.inceptia.ai"
            const ws_url = `${schema}://${hostname}/webchat/ws/${this.session_id}-${client_code}/${this.correlation_id}`
            sessionStorage.setItem('session_id', `${this.session_id}-${client_code}`)
            sessionStorage.setItem('ws_url', ws_url)
            this.URLSocket = ws_url
        },
        uuidv4() {
            return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = (c === 'x') ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        async saveMessageMints(msgData) {
            try {
                const response = await this.$axios.post('/valuador/save_chatbot_messages', msgData)
                if (this.firstContactMsg) {
                    this.conversation_id = response.data.message.conversation_id
                    this.contact_id = response.data.message.sender_id
                    sessionStorage.setItem('conversation_id', this.conversation_id.toString())
                }
                return response.data.message
            } catch (error) {
                console.error(error)
            }
        },
        async websocketDisconnectedError() {
            try {
                let data = {
                    ws_url: this.URLSocket,
                    session_id: this.session_id,
                    correlation_id: this.correlation_id,
                    conversation_id: this.conversation_id,
                    contact_email: this.contact_email,
                }
                await this.$axios.post('/valuador/websocket-disconnected-error', data)
            } catch (error) {
                console.error(error)
            }
        },
        menuActionHandler({action}) {
            event.stopPropagation()
            switch (action.name) {
                case 'close':
                    this.popupClass = "popup-chatbot-close"
                    this.menuActions[0].name = 'open'
                    this.menuActions[0].title = 'Abrir chatbot'
                    break
                case 'open':
                    this.popupClass = "popup-chatbot-open"
                    this.menuActions[0].name = 'close'
                    this.menuActions[0].title = 'Cerrar chatbot'
                    break
            }
        },
        buildTitle(item) {
            let categoria = item.categoria ? item.categoria : this.tipos[item.tipo]
            let peso = item.cali_peso ? item.cali_peso : item.peso
            let regex = /(\d+(?:\.\d+)?)(\D+)/
            let kilataje = regex.exec(item.kilataje)
            let type = 'g'
            if (item?.unidades) type = this.weight_units[item.unidades] ? this.weight_units[item.unidades] : 'g'
            return `${item.prenda} de ${categoria} de ${kilataje[1]}${kilataje[2]} de ${peso} ${type}`
        },
        async get_chatbot_conversation_messages() {
            try {
                const response = await this.$axios.get('/valuador/get_chatbot_conversation_messages', {params: {conversation_id: this.conversation_id}})
                return response.data.conversation_messages
            } catch (error) {
                console.error(error)
            }
        },
        async getAssetInfo(slug) {
            try {
                const response = await this.$axios.get('/valuador/get_asset_info', {params: {slug: slug}})
                return response.data
            } catch (error) {
                console.error(error)
            }
        },
        async fetchMessages({options = {}}) {
            if (options.reset) {
                this.resetMessages()
            }

            let responseMessages = []

            await this.get_chatbot_initial_messages()
            if (this.conversation_id === 0) {
                await this.pushBotUserMsg({text: this.initialMessage})
                this.messagesLoaded = true
            }

            if (this.getMessages) {
                responseMessages = await this.get_chatbot_conversation_messages()
            }

            if (responseMessages.length === 0) {
                this.messagesLoaded = true
            }

            this.addMessages(responseMessages)

            this.messagesLoaded = true
        },
        async openFile({file}) {
            try {
                let url = file.file.url

                if (url.indexOf('public-assets') !== -1) {
                    let urlObject = new URL(url)
                    url = urlObject.pathname
                }
                const response = await fetch(url)
                const data = await response.blob()
                const reader = new FileReader()
                reader.onloadend = () => {
                    const base64data = reader.result.split(',')[1]
                    const dataUrl = `data:${file.file.type};base64,${base64data}`
                    const link = document.createElement('a')
                    link.href = dataUrl
                    const mimeType = file.file.type.split('/').pop()
                    link.download = `${file.file.name || 'image'}.${mimeType}`
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }

                reader.readAsDataURL(data)
            } catch (error) {
                console.error('Error downloading the image:', error)
            }
        },
        addMessages(messages) {
            for (let message of messages) {
                let msg = {
                    _id: this.messages.length,
                    content: message.value.text ? message.value.text : '',
                    senderId: message.sender_type === 'Contact' ? this.currentUserId : '4321',
                    timestamp: new Date(message.created_at).toString().substring(16, 21),
                    date: new Date(message.created_at).toLocaleDateString('es-MX', this.dateOptions),
                    disableActions: this.msgDisableActions,
                    disableReactions: this.msgDisableReactions,
                }

                if (message.sender_type === 'Contact') {
                    this.firstContactMsg = false
                    this.showFiles = true
                    this.contact_id = message.sender_id
                }

                if (message.type === 'image') {
                    msg.files = [
                        {
                            name: message.value.name,
                            size: message.value.size,
                            type: message.value.mime_type,
                            url: `https://prestaprenda.mints.cloud/public-assets/${message.value.slug}`,
                        }
                    ]
                }

                this.messages.push(msg)
            }
        },
        resetMessages() {
            this.messages = []
            this.messagesLoaded = false
        },
        async pushCurrentUserMsg(message, email = false, imgBase64 = null) {
            let msg = {
                _id: this.messages.length,
                content: message.content,
                senderId: this.currentUserId,
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toLocaleDateString('es-MX', this.dateOptions),
                disableActions: this.msgDisableActions,
                disableReactions: this.msgDisableReactions,
            }

            let type = "text"
            let imgData = null
            let extension = ''

            if (message.files) {
                msg.files = [
                    {
                        name: message.files[0].name,
                        size: message.files[0].size,
                        type: message.files[0].extension,
                        url: imgBase64,
                    }
                ]

                imgData = await this.optimizeFile(message.files[0].blob)
                type = "image"
                extension = message.files[0].extension
            }

            this.messages.push(msg)

            let register_email = false

            if (this.emailRegEx.test(message.content) && this.firstContactMsg) {
                register_email = true
                this.contact_email = message.content
                sessionStorage.setItem('contact_email', this.contact_email)
            }

            if (!register_email && this.firstContactMsg) return

            let formData = new FormData()

            let msgData = {
                message: message.content,
                type: type,
                conversation_id: this.conversation_id,
                sender_type: "Contact",
                sender_id: this.contact_id,
                register_email: register_email,
                file: imgData,
                msg_id: msg._id,
                extension: extension,
                image_URL: '',
                contact_email: this.contact_email,
            }

            if (this.register_given_name) {
                msgData.register_given_name = true
                this.register_given_name = false
            }

            if (this.register_last_name) {
                msgData.register_last_name = true
                this.register_last_name = false
            }

            Object.entries(msgData).forEach(
                ([key, value]) => formData.append(key, value)
            )

            await this.saveMessageMints(formData)
        },
        async pushBotUserMsg(content) {
            let text = content.text
            let type = 'text'
            let image_URL = ''

            if (!text) {
                text = ''
            }

            let messageDate = new Date()

            if (content.time) {
                messageDate = new Date(content.time * 1000)
            }

            let msg = {
                _id: this.messages.length,
                content: text,
                senderId: '4321',
                timestamp: messageDate.toString().substring(16, 21),
                date: messageDate.toLocaleDateString('es-MX', this.dateOptions),
                disableActions: this.msgDisableActions,
                disableReactions: this.msgDisableReactions,
            }

            if (this.conversation_id === 0) {
                this.messages.push(msg)
                return
            }

            if (content.image) {
                type = 'image'
                image_URL = content.image
            }

            let formData = new FormData()

            let msgData = {
                message: text,
                type: type,
                conversation_id: this.conversation_id,
                sender_type: "User",
                register_email: false,
                file: null,
                msg_id: 0,
                extension: '',
                image_URL: image_URL,
                contact_email: this.contact_email,
            }

            Object.entries(msgData).forEach(
                ([key, value]) => formData.append(key, value)
            )

            let responseMessage = await this.saveMessageMints(formData)

            if (type === 'image') {
                let responseAssetInfo = await this.getAssetInfo(responseMessage.value.slug)

                msg.files = [
                    {
                        name: responseAssetInfo.name,
                        size: responseAssetInfo.size,
                        type: responseAssetInfo.mime_type,
                        url: `https://prestaprenda.mints.cloud/public-assets/${responseMessage.value.slug}`,
                    }
                ]
            }

            this.messages.push(msg)
        },
        sendSocketFirstMessage(email) {
            let valor_total = 0
            let productos = {}
            let context = {
                "email": email,
                "appointments_url": `${window.location.host}/sucursales?ue=${email}`,
            }

            if (this.productList.length !== 0) {
                this.productList.forEach(item => {
                    valor_total += Math.round(Number(item.prestamo))

                    productos[this.buildTitle(item)] = item.prestamo
                })

                context.valor_meta = this.budget
                context.valor_total = valor_total
                context.productos = productos
            }

            let msg = {
                "type": "text",
                "input": "",
                "context": context,
            }

            this.socket.send(JSON.stringify(msg))
        },
        openMenu() {
            this.menuActionHandler({action: {name: 'open'}})
            this.showNotification = false
            this.notificationCount = 0
        },
        async sendMessage(message) {
            let type = "text"
            let input = message.content

            if (message.files) {
                type = "image"
            }

            let msg = {
                "type": type,
                "input": input,
            }

            if (this.firstContactMsg) {
                await this.pushCurrentUserMsg(message)

                if (!this.emailRegEx.test(message.content)) {
                    await this.pushBotUserMsg({text: this.retryMessage})
                    return
                }

                this.sendSocketFirstMessage(message.content)
                this.firstContactMsg = false
                this.showFiles = true
                return
            }

            if (type === "text") {
                await this.pushCurrentUserMsg(message)
                this.socket.send(JSON.stringify(msg))
            }

            if (type === "image") {
                this.convertImageToBase64(message.files[0].blob)
                    .then(async (base64Image) => {
                        msg.input = base64Image

                        await this.pushCurrentUserMsg(message, false, base64Image)
                        this.socket.send(JSON.stringify(msg))
                    })
                    .catch(error => {
                        console.error('Error converting image to base64:', error)
                    })
            }
        },
        convertImageToBase64(image) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()

                reader.onload = () => {
                    const base64Image = reader.result
                    resolve(base64Image)
                }

                reader.onerror = error => {
                    reject(error)
                }

                reader.readAsDataURL(image)
            })
        },
        async optimizeFile(file) {
            const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1080,
                useWebWorker: true
            }

            try {
                return await imageCompression(file, options);
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.popup-chatbot-open {
    display: block !important;
    position: fixed;
    width: 24rem;
    height: 24rem;
    line-height: 10px;
    bottom: 65px;
    right: 5rem;
    z-index: 1000;
}

.popup-chatbot-close {
    display: block !important;
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    width: 80px;
    height: 60px;
    bottom: 15%;
    right: 0;
    border-radius: 2rem 0 0 2rem;
    background: var(--prestaprenda-orange);

    .avatar-container {
        width: 50px;
        height: 50px;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }
}

.chat-header {
    position: relative;
    width: 100%;
    height: 100px;
    background: var(--prestaprenda-orange);
    border-radius: 1rem 1rem 0 0;
}

.header-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
        cursor: pointer;
    }

    .burger-button {
        height: 30px;
        width: 30px;

        div {
            height: 4px;
        }
    }
}

.bottom-caret {
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    width: 100%;
    height: 20px;
    background: white;
    border-radius: 1rem 1rem 0 0;
}

.avatar-container {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    left: 50%;
    top: 0;
    z-index: 99;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background: var(--prestaprenda-blue);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 70%;
        height: 70%;
    }
}

.chatbot-notification {
    position: absolute;
    width: 26px;
    height: 26px;
    margin: -5px 0 0 -5px;
    display: flex;
    justify-content: start;
    z-index: 101;
}

.chatbot-notification-container {
    color: #fff;
    background-color: #de3737;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin: 0;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px) {
    .popup-chatbot-open {
        width: 100%;
        right: 0;
    }
}
</style>